<template>
  <App-necker :title="'搜尋'" />
  <div class="container">
    <List :service="SearchService" :fields="fields">
      <template v-slot:item="slot">
        <Card
          :keyword="slot.keyword"
          :link="`/exhib/${slot.row.id}/${slot.row.slug}`"
          :title="slot.row.title"
          :cover="slot.row.banner"
        ></Card>
      </template>
    </List>
  </div>
</template>

<script>
import List from '@/pages/index/components/list/Index.vue';
import SearchService from '@/services/Exhib';
import Card from '@/pages/index/components/list/CardExhib.vue';
export default {
  components: {
    List,
    Card,
  },

  setup() {
    return {
      SearchService,
      fields: {
        keyword: null,
      },
    };
  },
};
</script>
